@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply transition-colors duration-200;
}

body {
  min-height: 100vh;
}

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
}

/* textarea {
  height: 4em;
  width: 95%;
} */
.cancleBtn {
  @apply p-2 text-sm font-medium  
  text-gray-900 bg-white border border-gray-300 
  focus:outline-none hover:bg-gray-100 
  focus:ring-4 focus:ring-gray-100 rounded-lg
  dark:bg-gray-800 dark:text-white 
  dark:border-gray-600 dark:hover:bg-gray-700 
  dark:hover:border-gray-600 dark:focus:ring-gray-700;
}
.deleteBtn {
  @apply px-1 text-sm font-medium
  text-white focus:outline-none 
  bg-red-600 rounded-lg 
  border border-gray-200 
  hover:bg-red-400 
  hover:text-gray-300 focus:ring-gray-100;
}
.error {
  @apply text-red-500 text-xs italic font-bold;
}
.success {
  @apply text-green-500 text-base italic font-bold;
}
.label {
  @apply flex my-1 text-sm font-medium text-gray-900 dark:text-orange-400;
}
.textInput {
  @apply bg-gray-50 border border-gray-300 
  text-gray-900 rounded-lg 
  focus:ring-blue-500 
  focus:border-blue-500 block w-full p-2.5
  dark:bg-gray-700 dark:border-gray-600 
  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
}
.textDisplay {
  @apply font-bold text-base bg-gray-200 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
.errorInput {
  @apply bg-gray-50 border border-red-700 text-gray-900 
  rounded-lg 
  focus:ring-blue-500 focus:border-blue-500 
  block w-full p-2.5 
  dark:bg-gray-700 dark:border-red-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-red-500;
}
.link {
  @apply font-medium text-blue-600 underline dark:text-blue-400 hover:no-underline;
}
.link2 {
  @apply font-medium text-blue-600 dark:text-blue-400 hover:no-underline;
}
.button {
  @apply bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full;
}
.body {
  @apply bg-slate-300 dark:bg-slate-900 h-full min-h-screen;
}
.text {
  @apply text-gray-900 dark:text-white mt-3 text-base;
}
.form {
  @apply max-w-sm mx-auto flex flex-col mt-5;
}
.profile {
  @apply max-w-sm mx-auto flex flex-col mt-5 border-solid;
}
.profileValue {
  @apply text-3xl font-bold dark:text-white;
}
.title {
  @apply block mb-4 font-medium text-2xl text-gray-900 dark:text-orange-400;
}
.titleLogin {
  @apply self-center font-semibold dark:text-white;
}
.navBar {
  @apply bg-gray-200 border-gray-200 dark:bg-gray-900;
}
.editButton {
  @apply ml-1 px-2 text-white bg-blue-700 
  hover:bg-blue-800 focus:outline-none 
  focus:ring-2 focus:ring-blue-300 font-medium rounded-full 
  text-sm text-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
}
.verify {
  @apply ml-1 px-2 text-white bg-green-700 
  /* hover:bg-green-800 focus:outline-none  */
  focus:ring-2 focus:ring-green-300 font-medium rounded-full 
  text-sm text-center  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800;
}
.notVerify {
  @apply ml-1 px-2 text-white bg-orange-700 
  /* hover:bg-green-800 focus:outline-none  */
  focus:ring-2 focus:ring-orange-300 font-medium rounded-full 
  text-sm text-center  dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800;
}
.updateButton {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
  focus:ring-blue-300 font-medium rounded-lg text-sm 
  px-5 py-2.5 me-2 mb-2 
  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800;
}
.addButton {
  @apply text-white bg-orange-500 
  hover:bg-orange-600 focus:ring-4 
  focus:outline-none focus:ring-red-300 dark:focus:ring-orange-600 
  font-medium rounded-lg text-sm inline-flex items-center 
  px-5 py-2.5 text-center;
}
.cancelButton {
  @apply text-gray-900 bg-white border border-gray-300 
  focus:outline-none hover:bg-gray-100 
  focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm 
  px-5 py-2.5 me-2 mb-2 
  dark:bg-gray-800 dark:text-white 
  dark:border-gray-600 dark:hover:bg-gray-700 
  dark:hover:border-gray-600 dark:focus:ring-gray-700;
}

/* Menu */
.menuList {
  @apply p-1 text-lg;
}
.menuSelection {
  @apply dark:border-transparent border-2 block border-solid rounded bg-transparent text-gray-900 rounded hover:bg-gray-100 p-1 dark:text-white dark:hover:text-orange-200 dark:hover:bg-gray-700 dark:hover:text-white dark:hover:bg-orange-400 dark:border-gray-700;
}
/* .menuSelection {
  @apply text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700;
} */

.selected {
  @apply border-orange-400 text-orange-700 dark:text-orange-400 dark:border-orange-400;
}
/* .selected {
  @apply border-orange-400 border-2 block py-2 px-3 text-white border-solid rounded md:bg-transparent md:text-orange-700 md:p-0 md:dark:text-orange-400;
}
 */
.menuBar {
  @apply flex flex-col font-medium mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700;
}

/* This is For Tag Drop Down Search */
.textInput {
  @apply bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
  focus:ring-blue-500 focus:border-blue-500 
  block w-full p-2.5 
  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
.container {
  @apply relative
  flex 
  items-center 
  gap-[0.5em] 
  p-0;
}

.value {
  @apply grow flex gap-[0.5em] flex-wrap;
}

.bookmarkForm {
  @apply overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 
  justify-center items-center 
  md:inset-0 
  
  h-[calc(100%-1rem)] 
  max-h-full;
}
.bookmarkForm2 {
  @apply bg-transparent 
  w-screen
  max-w-md 
  min-h-fit
  max-h-full
  lg:overflow-y-visible
  md:overflow-y-scroll
  sm:overflow-y-scroll
  overflow-x-visible
  backdrop:bg-gray-600 backdrop:opacity-20;
}

.bookmarkFormOG {
  @apply bg-transparent w-full 
  max-w-md max-h-full 
  overflow-hidden
  overflow-y-visible
  overflow-x-visible
  backdrop:bg-gray-600 backdrop:opacity-20;
}

.options {
  @apply absolute max-h-[15em] overflow-y-auto w-full 
  bg-[white] z-[100] 
  dark:bg-gray-700
  m-0 p-0 rounded-[0.25em] border-[0.05em] border-solid border-[#777] 
  font-bold
  text-lg
  left-0 top-[calc(100%_+_0.25em)];
  list-style: none;
}
/* .options.show {
  @apply block;
} */
.option {
  @apply cursor-pointer px-[0.5em] py-[0.25em];
}

.highlighted {
  @apply bg-[hsl(198,52%,91%)] dark:bg-[hsl(200,66%,32%)];
}
